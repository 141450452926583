<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="10" sm="8" md="6" lg="4" class="text-center">
        <!--カバー画像-->
        <v-row>
          <v-col cols="12" class="text-center">
            <h2>ポルノ画像検出</h2>
            <p>画像を選択して、検出情報を表示する。</p>
            <v-card class="mx-auto">
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
                ></v-progress-linear>
              </template>

              <v-img contain :src="coverImg"></v-img>
            </v-card>
          </v-col>
        </v-row>
        <!--ファイルアップロード-->
        <v-row justify="center">
          <v-col cols="12" class="text-center">
            <v-btn
              color="primary"
              class="text-none"
              rounded
              depressed
              @click="onButtonClick"
            >
              <v-icon left> mdi-cloud-upload </v-icon>
              {{ buttonText | ellipsis }}
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onFileChanged"
            />
          </v-col>
        </v-row>
        <!--結果領域-->
        <v-row v-if="results.length > 0">
          <v-col cols="12" class="text-center">
            <v-card class="mx-auto">
              <v-card-title>検出情報：</v-card-title>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">ラベル</th>
                      <th class="text-left">シーン</th>
                      <th class="text-left">得点</th>
                      <th class="text-left">提案</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in results"
                      :key="item.name"
                      :class="
                        item.suggestion === 'Block'
                          ? 'dager'
                          : item.suggestion === 'Review'
                          ? 'waring'
                          : ''
                      "
                    >
                      <td class="text-left">{{ item.label }}</td>
                      <td class="text-left">{{ item.scene }}</td>
                      <td class="text-left">{{ item.score }}</td>
                      <td class="text-left">{{ item.suggestion }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!--サンプル画像-->
    <v-row>
      <v-col
        cols="4"
        sm="3"
        md="2"
        lg="1"
        class="text-center"
        v-for="(img, index) in sampleImgs"
        :key="img"
      >
        <v-card class="mx-auto" max-width="100">
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <v-img height="100" :src="img" @click="detectSample(index)"></v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import baseUrl from "@/api/baseUrl";
import { init, detectSample, detectImage } from "@/api/porn";
export default {
  data: () => ({
    defaultButtonText: "画像をアップロード",
    selectedFile: null,
    isSelecting: false,
    coverImg: require("../assets/img/pn-cover.png"),
    sampleImgs: [],
    results: [],
    overlay: false,
  }),
  mounted: function () {
    init().then((res) => {
      if (res.data) {
        this.sampleImgs = res.data.map((img) => baseUrl + img);
      }
    });
  },
  computed: {
    buttonText() {
      return this.selectedFile
        ? this.selectedFile.name
        : this.defaultButtonText;
    },
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true;
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      if (this.selectedFile) {
        this.overlay = true;
        this.results = [];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.coverImg = e.target.result;
        };
        reader.readAsDataURL(this.selectedFile);
        this.isSelecting = false;
        detectImage(this.selectedFile)
          .then((res) => {
            this.results = res.data.labelResults.sort((a, b) =>
              a.score > b.score ? -1 : b.score > a.score ? 1 : 0
            );
            this.overlay = false;
            console.log(res);
          })
          .catch((error) => {
            this.overlay = false;
            console.error(error);
          });
      }
    },
    detectSample(index) {
      this.selectedFile = null;
      this.overlay = true;
      this.results = [];
      this.coverImg = this.sampleImgs[index];
      detectSample(index + 1)
        .then((res) => {
          this.results = res.data.labelResults.sort((a, b) =>
            a.score > b.score ? -1 : b.score > a.score ? 1 : 0
          );
          this.overlay = false;
          console.log(res);
        })
        .catch((error) => {
          this.overlay = false;
          console.error(error);
        });
    },
  },
  filters: {
    ellipsis(value) {
      let len = value.length;
      if (!value) return "";
      if (value.length > 20) {
        return value.substring(0, 8) + "..." + value.substring(len - 8, len);
      }
      return value;
    },
  },
};
</script>

<style>
.v-icon--left {
  margin-right: 8px;
}
.dager {
  background: #f44336;
}
.waring {
  background: #ff9800;
}
</style>