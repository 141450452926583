import request from "./request"

export const init = () =>{
    return request.get("/porn")
}

export const detectSample = (index) =>{
    return request.get("/porn/sample/"+index)
}

export const detectImage = (file) =>{
    let formData = new FormData();
    formData.append("file", file);
    return request.post("/porn/detect",formData,{
        headers: {
            "Content-Type": "multipart/form-data",
          },
    })
}